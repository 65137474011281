import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import $ from 'jquery';
import Header from '../component/Header';
import { api_url } from '../Config';
import Axios from 'axios';
// import $ from 'jquery';
import loader from '../assets/images/favicon-icon.png';
// import swal from 'sweetalert';
// import { Redirect } from 'react-router';
import vectorsmart from '../assets/images/Vector-Smart-Object.png';
import logo from '../assets/images/LOGO.png';
import { ProductConsumer } from "../context";
class MyOrder extends Component {
    constructor() {
        super();
        this.state = {
            redirectToReferer: false,
            base_url: api_url.base_url,
            orders: [],
            loading: false,
            success_message: (sessionStorage.getItem('successfull')) ? sessionStorage.getItem('successfull') : '',
        };
    }
    componentDidMount() {
        $(".close_btn_icon i,.overlay_poppu").click(function () {
            $(".overlay_poppu,.popup_orderbox").fadeOut();
        });
        console.log(localStorage)
        if (sessionStorage.getItem('successfull')) {
            // window.location.reload();
            $(".overlay_poppu,.popup_orderbox").fadeIn();
            sessionStorage.removeItem('successfull')
        }
        let user_id = localStorage.getItem('user_id');
        this.setState({ loading: true });
        Axios.get(this.state.base_url + 'api/v2/order_all/' + user_id)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        orders: response.data.data,
                        loading: false
                    });
                }
            });

    }
    componentDidUpdate() {
        $(".close_btn_icon i,.overlay_poppu").click(function () {
            $(".overlay_poppu,.popup_orderbox").fadeOut();
        });
    }

    render() {
        function paymentType(payment) {
            if (payment === 'cash_on_delivery') {
                return 'COD';
            } else {
                return payment
            }
        }
        return (
            <ProductConsumer>
                {value => {
                    return (
                        <React.Fragment>


                            <div className="yellow_bg border_none order_page">

                                <Header title="My Orders" link="/home" />

                                <section className="login_box__view listing_view purple_bg" id="height_box">
                                    <div className="row">
                                        {(
                                            this.state.success_message !== '' ?
                                                <React.Fragment>
                                                    <div className="overlay_poppu"></div>
                                                    <div className="popup_orderbox" style={{ zIndex: '9999', position: 'fixed' }}>
                                                        <div className="overlayimg">
                                                            <img src={vectorsmart} alt="" />
                                                        </div>
                                                        <div className="close_btn_icon">
                                                            <i className="lnr lnr-cross"></i>
                                                            <img src={logo} alt="" />


                                                            {<p className="shukria_box">   آوڈر ایکسپریس دوکان کے          <span>! انتخاب کا شکریہ</span></p>}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                : ""
                                        )}
                                    </div>
                                    <div className="main_wrap dashboard_box purple_bg myorder_page">
                                        <div className="searchbar">
                                            <input type="text" placeholder="Search..." name="" />
                                            <span className="lnr lnr-magnifier"></span>
                                        </div>


                                        {
                                            this.state.loading ?
                                                <div className="loading_img checkout_loaders">
                                                    <img src={loader} alt="loader" />
                                                </div>
                                                :
                                                this.state.orders.length > 0 &&
                                                this.state.orders.map((data, id) => {
                                                    return (
                                                        <div className="assigned__order" key={id}>
                                                            <div className="inner_assigned">
                                                                <Link to={`/orderdetails/${data.id}`}>
                                                                    <p>
                                                                        <svg className="jss314 jss1168" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><g><path d="M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 18H5c-.55 0-1-.45-1-1V8h16v12c0 .55-.45 1-1 1z"></path></g></svg> {data.time_slot}
                                                                        <span>
                                                                            {data.order_status}</span>
                                                                    </p>
                                                                </Link>
                                                                <Link to={`/orderdetails/${data.id}`}>
                                                                    <p className="orderno">#{data.code}
                                                                        <span>  {data.created_at}</span>
                                                                    </p>
                                                                </Link>
                                                                <p className="numberbox"> Payment Mode:
                                                                    {paymentType(data.payment_type)}
                                                                    <span>
                                                                        <Link to="#">Rs. {data.grand_total}</Link>
                                                                    </span>
                                                                </p>
                                                                <p className="address">
                                                                    <svg className="jss314 jss1221 jss1219" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zM8.5 14.21c0 .43-.36.79-.79.79-.25 0-.49-.12-.64-.33L4.75 11.5v2.88c0 .35-.28.62-.62.62s-.63-.28-.63-.62V9.79c0-.43.36-.79.79-.79h.05c.26 0 .5.12.65.33l2.26 3.17V9.62c0-.34.28-.62.63-.62s.62.28.62.62v4.59zm5-4.57c0 .35-.28.62-.62.62H11v1.12h1.88c.35 0 .62.28.62.62v.01c0 .35-.28.62-.62.62H11v1.11h1.88c.35 0 .62.28.62.62 0 .35-.28.62-.62.62h-2.53c-.47 0-.85-.38-.85-.85v-4.3c0-.45.38-.83.85-.83h2.53c.35 0 .62.28.62.62v.02zm7 4.36c0 .55-.45 1-1 1h-4c-.55 0-1-.45-1-1V9.62c0-.34.28-.62.62-.62s.62.28.62.62v3.89h1.13v-2.9c0-.35.28-.62.62-.62s.62.28.62.62v2.89h1.12V9.62c0-.35.28-.62.62-.62s.62.28.62.62V14z"></path></svg> {data.note}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                        }
                                    </div>
                                </section>


                                {/* <div className="footer_menu">
                        <ul>
                            <li>
                                <Link to="/home">

                                    <svg viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#690048" /></svg>

                                </Link>
                            </li>

                            <li>
                                <Link to="/profile">
                                    <svg viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM12 13c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4zm6 5H6v-.99c.2-.72 3.3-2.01 6-2.01s5.8 1.29 6 2v1z" fill="#690048"></path></svg>

                                </Link>
                            </li>

                            <li>
                                <Link to="login">
                                    <svg viewBox="0 0 24 24"><path d="M5 3h6a3 3 0 0 1 3 3v4h-1V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4h1v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm3 9h11.25L16 8.75l.664-.75l4.5 4.5l-4.5 4.5l-.664-.75L19.25 13H8v-1z" fill="#690048" />
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    </div> */}
                            </div>


                        </React.Fragment>
                    )
                }
                }
            </ProductConsumer >
        )
    }
}
export default MyOrder;
