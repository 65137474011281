import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import axios from 'axios';
import Modal from '../component/Modal';
import loader from '../assets/images/qty_boxes_03.png';
import { ProductConsumer } from "../context";
import { api_url } from '../Config';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import $ from 'jquery';
import vectorsmart from '../assets/images/Vector-Smart-Object.png';
import logo from '../assets/images/LOGO.png';
class Home extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
            products: [],
            mainCategory: [],
            brands: [],
            sepecialProducts: [],
            img_url: api_url.img_url,
            specialLoading: true,
            default_img: 'assets/img/placeholder.jpg',
            success_message: (sessionStorage.getItem('successfull')) ? sessionStorage.getItem('successfull') : '',
        }
        this.closeModal = this.closeModal.bind(this);
    }
    closeModal() {
        $(".overlay_poppu,.popup_orderbox").fadeOut();
    }
    componentDidMount() {
        if (sessionStorage.getItem('successfull')) {
            // window.location.reload();
            $(".overlay_poppu,.popup_orderbox").fadeIn();
            sessionStorage.removeItem('successfull')
        }
        axios.get(this.state.base_url + 'api/v2/categories/all')
            .then((response) => {

                if (response.status === 200) {
                    this.setState({
                        mainCategory: response.data.data
                    })
                }
            });
        axios.get(this.state.base_url + 'api/v2/products/special_offers/' + null)
            .then((response) => {

                if (response.status === 200) {
                    this.setState({
                        sepecialProducts: response.data.data,
                        specialLoading: false
                    })
                }
            });
        axios.get(this.state.base_url + 'api/v1/brands')
            .then((response) => {
                // console.log(response.data)
                this.setState({
                    brands: response.data.data
                })
            });
    }
    componentWillMount() {
        if (localStorage.getItem("approved")) {
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }
    render() {
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 4
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2
            }
        };
        if (this.state.redirectToReferrer) {
            return (<Redirect to='/' />)
        }
        return (
            <ProductConsumer>
                {value => {
                    return (
                        <React.Fragment>
                            <Modal />
                            <div className="yellow_bg border_none" id="slider_listing">
                                <Header title="ORDER EXPRESS" tagline="دوکان" link="/home" />
                                <div className="row">
                                    {(
                                        this.state.success_message !== '' ?
                                            <React.Fragment>
                                                <div className="overlay_poppu"></div>
                                                <div className="popup_orderbox" style={{ zIndex: '9999', position: 'fixed' }}>
                                                    <div className="overlayimg">
                                                        <img src={vectorsmart} alt="" />
                                                    </div>
                                                    <div className="close_btn_icon">
                                                        <i onClick={() => {
                                                            this.closeModal();
                                                            value.clearCart();
                                                        }} className="lnr lnr-cross"></i>
                                                        <img src={logo} alt="" />


                                                        {<p className="shukria_box">   آوڈر ایکسپریس دوکان کے          <span>! انتخاب کا شکریہ</span></p>}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            : ""
                                    )}
                                </div>
                                <div className="yellow_bar" id="dark_bar"></div>
                                <div className="brand_bg" id="special_offer">
                                    <h4>Today's Offer</h4>
                                </div>
                                {
                                    this.state.specialLoading ?
                                        <div className="loading_img checkout_loaders">
                                            <img src={loader} alt="loader" />
                                        </div>
                                        :
                                        <Carousel responsive={responsive}>
                                            {
                                                this.state.sepecialProducts.map((data, id) => {
                                                    return (
                                                        <div className="special_offer_box" key={id}>
                                                            <Link to="#" className="order_adress">
                                                                <div className="items_box">
                                                                    <div className="tag_box">
                                                                        <h6>{data.unit}</h6>
                                                                    </div>
                                                                    <img className="cocacola_img" src={this.state.img_url + data.thumbnail_image} alt="" />
                                                                    <div className="row">
                                                                        <div className="col-sm-12 brand_fulname">
                                                                            <span>{data.name}</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-6 line_through">
                                                                            {
                                                                                data.original_price > 0 &&
                                                                                <h5>Rs. {data.original_price}</h5>
                                                                            }
                                                                        </div>
                                                                        <div className="col-sm-6 brand__name">
                                                                            <span className="price__box">Rs. {data.sale_price}</span>
                                                                        </div>
                                                                    </div>
                                                                    <Link to="#" onClick={() => {
                                                                        // value.addToCart(id);
                                                                        value.openModal(data);
                                                                    }}>ابھی خریدیں </Link>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>

                                }

                                {
                                    this.state.sepecialProducts.length > 0 &&
                                    <div className="login_btn view_all">
                                        <Link to="/itemlist/special/all" >
                                            <button type="button">
                                                <span>View all</span>
                                            </button>
                                        </Link>
                                    </div>
                                }

                                <div className="yellow_bar" id="all_products"></div>
                                <div className="brand_bg">
                                    <h4>PRODUCTS</h4>
                                </div>

                                <div className="all_products_box">
                                    <ul>
                                        {
                                            this.state.mainCategory.length > 0 ? this.state.mainCategory.map((data, id) => {
                                                return (
                                                    <li key={id}>
                                                        <Link to={`/brands/${data.id}`}>
                                                            <h4>{data.name}</h4>
                                                            <img className="cocacola_img" src={this.state.img_url + data.banner} alt="" />
                                                        </Link>
                                                    </li>
                                                );
                                            })
                                                :
                                                <div className="loading_img">
                                                    <img src={loader} alt="loader" />
                                                </div>
                                        }

                                    </ul>
                                </div>


                                <div className="yellow_bar"></div>
                                <div className="brand_bg">
                                    <h4>BRANDS</h4>
                                </div>
                                <div className="brands_logos home_brands">
                                    <ul>
                                        {
                                            this.state.brands.length > 0 ? this.state.brands.map((data, id) => {

                                                return (
                                                    <li key={id}>
                                                        <Link to={`/itemlist/${data.id}/all`}>
                                                            {/* <h4>{data.name}</h4> */}
                                                            <img className="cocacola_img" src={this.state.img_url + data.logo} alt="" />
                                                        </Link>
                                                    </li>
                                                );
                                            })
                                                :
                                                <div className="loading_img">
                                                    <img src={loader} alt="loader" />
                                                </div>
                                        }

                                    </ul>
                                </div>



                            </div>

                            <Footer />
                        </React.Fragment>
                    )
                }
                }
            </ProductConsumer >
        )
    }
}
export default Home;
