import React, { Component } from 'react';
import favicon_icon from '../assets/images/favicon-icon.png';
import Header from '../component/Header';
import { api_url } from '../Config';
import Axios from 'axios';
// import swal from 'sweetalert';
class Profile extends Component {
    constructor() {
        super();
        this.props = {
            redierctToReferer: false,
            base_url: api_url.base_url,
            profile: [],
            loading: true,
            name: "",
            shopname: "",
            area: "",
            shopaddress: "",
            cnic: "",
        }
        this.submitProfile = this.submitProfile.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        Axios.get('https://orderexpress.com.pk/api/v2/user/info_api/' + user_id)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        profile: response.data.data[0],
                        loading: false
                    });
                }
            });
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    submitProfile() {
        // swal('profile submit')
    }

    render() {

        return (
            <React.Fragment>


                <body className="yellow_bg" id="register_page">

                    <Header title="Delivery Address" link="/home" />

                    <div className="login_box">
                        <form onSubmit={this.submitProfile} className="middle_form profilebox">
                            <div className="main_head">
                                <img src={favicon_icon} alt="" />
                            </div>
                            <div className="form_box">
                                <i className="lnr lnr-map-marker"></i>
                                <input type="text" placeholder="Enter Your Address" required={true} onChange={this.onChange} name="enter your address" />
                            </div>
                            <div className="form_box">
                                <i className="lnr lnr-apartment"></i>
                                <input type="text" placeholder="Building" required={true} onChange={this.onChange} name="building" />
                            </div>
                            <div className="form_box">
                                <i className="lnr lnr-map-marker"></i>
                                <input type="text" placeholder="Street" required={true} onChange={this.onChange} name="street" />
                            </div>
                            <div className="form_box">
                                <i className="fa  fa-building-o"></i>
                                <input type="text" placeholder="Floor/Unit" required={true} onChange={this.onChange} name="floor/unit" />
                            </div>
                            <div className="form_box">
                                <i className="lnr lnr-apartment"></i>
                                <select>
                                    <option>Select Area</option>
                                    <option>Islamabad</option>
                                </select>
                            </div>


                            <div className="login_btn">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>

                </body>


            </React.Fragment>
        )
    }
}
export default Profile;
