import React, { Component } from 'react';
import favicon_icon from '../assets/images/favicon-icon.png';
import Header from '../component/Header';
import { api_url } from '../Config';
import Axios from 'axios';
import swal from 'sweetalert';
class Profile extends Component {
    constructor() {
        super();
        this.state = {
            redierctToReferer: false,
            base_url: api_url.base_url,
            profile: [],
            loading: true,
            name: "",
            address: "",
            city: "",
            country: "",
            postal_code: "",
            password: "",
            repassword: "",
            phone: ""
        }
        this.submitProfile = this.submitProfile.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        let user_id = localStorage.getItem('user_id');
        Axios.get('https://orderexpress.com.pk/api/v2/user/info_api/' + user_id)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.data.length > 0) {
                        this.setState({
                            profile: response.data.data[0],
                            loading: false,
                            name: response.data.data[0].name,
                            city: response.data.data[0].city,
                            country: response.data.data[0].country,
                            address: response.data.data[0].address,
                            postal_code: response.data.data[0].postal_code,
                            phone: response.data.data[0].phone,
                        });
                    }
                }
            });
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    submitProfile(e) {
        e.preventDefault();
        let functions = this;
        let user_id = localStorage.getItem('user_id');
        let password = this.state.password;
        if (password !== "" && this.state.password.length < 6) {
            swal('Password must be 6 characters long');
            return;
        }
        if (password !== "" && this.state.repassword !== this.state.password) {
            swal('password not matched');
            return;
        }
        this.setState({ loadingBtn: true })
        Axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.state.base_url + 'api/v2/user/info/update',
            data: {
                user_id: user_id,
                name: this.state.name,
                address: this.state.address,
                city: this.state.city,
                password: this.state.password,
            }
        }).then(function (response) {
            functions.setState({ loadingBtn: false })
            swal(response.data.message);
        });
    }

    render() {
        const { loadingBtn } = this.state;
        return (
            <React.Fragment>


                <body className="yellow_bg" id="register_page">

                    <Header title="My Profile" link="/home" />

                    <div className="login_box">
                        <form onSubmit={this.submitProfile} autocomplete="off" className="middle_form profilebox">
                            <div className="main_head">
                                <img src={favicon_icon} alt="" />
                            </div>
                            <div className="form_box">
                                <i className="lnr lnr-user"></i>
                                <input type="text" placeholder="Name" required={true} onChange={this.onChange} name="name" value={this.state.name} />
                            </div>
                            <div className="form_box">
                                <i className="lnr lnr-home"></i>
                                <input type="text" placeholder="City" required={true} onChange={this.onChange} name="city" value={this.state.city} />
                            </div>
                            <div className="form_box">
                                <i className="lnr lnr-home"></i>
                                <input type="text" placeholder="Phone" readOnly={true} required={true} onChange={this.onChange} value={this.state.phone} />
                            </div>
                            <div className="form_box">
                                <i className="lnr lnr-map-marker"></i>
                                <input type="text" placeholder="Address" required={true} onChange={this.onChange} name="address" value={this.state.address} />
                            </div>
                            {/* <div className="form_box">
                                <i className="lnr lnr-license"></i>
                                <input type="text" placeholder="Postal Code" onChange={this.onChange} name="postal_code" value={this.state.postal_code} />
                            </div> */}
                            <div className="form_box">
                                <i className="lnr lnr-license"></i>
                                <input type="password" placeholder="Password" onChange={this.onChange} name="password" autocomplete="off" />
                            </div>
                            <div className="form_box">
                                <i className="lnr lnr-license"></i>
                                <input type="password" placeholder="Confirm Password" onChange={this.onChange} name="repassword" autocomplete="off" />
                            </div>


                            <div className="login_btn">
                                <button
                                    type="submit"
                                    disabled={loadingBtn}
                                >
                                    {loadingBtn && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {loadingBtn && <span>Loading...
                                    </span>}
                                    {!loadingBtn && <span>Submit</span>}
                                </button>
                            </div>
                        </form>
                    </div>

                </body>


            </React.Fragment>
        )
    }
}
export default Profile;
