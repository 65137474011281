import React, { Component } from 'react';
import logo from '../assets/images/LOGO.png';
import logo_icon from '../assets/images/Vector-Smart-Object.png';
import favicon_icon from '../assets/images/favicon-icon.png';
import $ from 'jquery';
import { Link, Redirect } from 'react-router-dom';
import Axios from "axios";
import { api_url } from '../Config';
import swal from 'sweetalert';
import vectorsmart from '../assets/images/Vector-Smart-Object.png';
class Login extends Component {
    constructor() {
        super();
        this.state = {
            base_url: api_url.base_url,
            redirectToReferrer: false,
            phone: "",
            password: "",
            loadingBtn: false,
            loginToHome: false,
            success_message: (sessionStorage.getItem('successfull')) ? sessionStorage.getItem('successfull') : '',
        };
        this.getLogin = this.getLogin.bind(this);
        this.onChange = this.onChange.bind(this);
        this.changeIcon = this.changeIcon.bind(this);
    }
    changeIcon() {
        if ($('.password').attr('type') === "text") {
            $('.password').attr('type', 'password');
            $('.right_eye').addClass('fa-eye');
            $('.right_eye').removeClass('fa-eye-slash');

        } else {
            $('.password').attr('type', 'text');
            $('.right_eye').removeClass('fa-eye');
            $('.right_eye').addClass('fa-eye-slash');
        }
    }
    componentDidMount() {
        $(".close_btn_icon i,.overlay_poppu").click(function () {
            $(".overlay_poppu,.popup_orderbox").fadeOut();
        });
        if (sessionStorage.getItem('successfull')) {
            // window.location.reload();
            $(".overlay_poppu,.popup_orderbox").fadeIn();
            sessionStorage.removeItem('successfull')
        }
        $(".loader").delay(120).fadeOut("slow");
        $("#overlayer").delay(120).fadeOut("slow");
        // });3400
        // $(window).load(function() {
        $(".loader").delay(120).fadeOut("slow");
        $("#overlayer").delay(120).fadeOut("slow");
    }
    getLogin(event) {
        event.preventDefault();

        let functions = this;
        if (this.state.phone === '' || this.state.password === '') {
            swal('Phone and Password required.')
        } else if (this.state.phone.length !== 10) {
            swal('Invalid Phone Number format. Format should be 3XXXXXXXXX');
        } else {
            this.setState({ loadingBtn: true })
            Axios({
                method: 'post',
                // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.state.base_url + 'api/v2/auth/loginWithApi',
                data: {
                    email: this.state.phone,
                    password: this.state.password
                }
            }).then(function (response) {
                functions.setState({ loadingBtn: false })
                if (response.data.user_data) {
                    if (response.data.user_data.code === "ok") {
                        localStorage.setItem("token_id", response.data.opt_code);
                        localStorage.setItem("user_id", response.data.user_id);
                        localStorage.setItem("user_name", response.data.user_name);
                        localStorage.setItem("balance", response.data.user_data.balance);
                        localStorage.setItem("phone", functions.state.phone);
                        localStorage.setItem("password", functions.state.password);
                        localStorage.setItem("approved", 0);
                        functions.setState({
                            redirectToReferrer: true,
                        })
                    } else {
                        swal("Phone or password is invalid");
                    }
                } else {
                    swal("Phone or password is invalid");
                }



            });
        }

        // 
    }
    componentWillMount() {
        if (localStorage.getItem("approved") === 0) {
            this.setState({ redirectToReferrer: true });
        }
        if (localStorage.getItem("approved") === '1') {
            this.setState({ loginToHome: true });
        }
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { loadingBtn } = this.state;
        if (this.state.redirectToReferrer) {
            return (<Redirect to='otpno' />)
        }
        if (this.state.loginToHome) {
            return (<Redirect to='/home' />)
        }
        return (
            <div className="yellow_bg" style={{ height: '100vh' }}>
                <div className="row">
                    {(
                        this.state.success_message !== '' ?
                            <React.Fragment>
                                <div className="overlay_poppu"></div>
                                <div className="popup_orderbox" style={{ zIndex: '9999', position: 'fixed' }}>
                                    <div className="overlayimg">
                                        <img src={vectorsmart} alt="" />
                                    </div>
                                    <div className="close_btn_icon">
                                        <i className="lnr lnr-cross"></i>
                                        <img src={logo} alt="" />
                                        {<p className="shukria_box"><span>آپ کا پاس ورڈ ری سیٹ ہو چکا ہے۔
                                            لاگ ان کریں</span></p>}
                                    </div>
                                </div>
                            </React.Fragment>
                            : ""
                    )}
                </div>
                <span className="loader">
                    <div className="logobox">
                        <img src={logo} alt="" />
                        <img className="logo_icon" src={logo_icon} alt="" />
                        <p>Powered by Order Express</p>
                    </div>
                </span>


                <div className="login_box">
                    <div className="main_head">
                        <img src={favicon_icon} alt="" />
                        <p>آپ کی اپنی دوکان </p>
                    </div>
                    <form onSubmit={this.getLogin} className="middle_form">
                        <div className="form_box number_add">
                            <i className="lnr lnr-phone"></i>
                            <span className="country_code">+92</span>
                            <input type="text" placeholder="3XXXXXXXXX" required="required" name="phone" onChange={this.onChange} />
                        </div>
                        <div className="form_box eye_icon">
                            <i className="lnr lnr-lock"></i>
                            <input type="password" className="password" placeholder="Password" required="required" name="password" onChange={this.onChange} />
                            <i className="fa fa-eye right_eye" onClick={this.changeIcon} aria-hidden="true"></i>
                        </div>
                        <div className="forget_pas">
                            <Link to="/forget-password"> کیا آپ پاسورڈ بھول گئے ہیں؟</Link>
                        </div>
                        <div className="login_btn">

                            {/* <button type="submit">
                                لاگ ان
                            </button> */}
                            <button
                                type="submit"
                                disabled={loadingBtn}
                            >
                                {loadingBtn && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loadingBtn && <span>لوڈ ہو رہا ہے
                                </span>}
                                {!loadingBtn && <span>لاگ ان</span>}
                            </button>
                            {/* </Link> */}

                            <Link to="/register">رجسٹر کریں </Link>
                        </div>
                    </form>
                </div>

            </div>
        )
    }
}
export default Login;